import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es7.object.entries.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { toNumber } from 'lodash';
import groupBy from 'lodash/groupBy';
import { ConceptKind, ConceptStatus, ImplementationStatus, RiskManagementScheme, useCreateUserinputConceptLinkWithCustomSelectedConceptMutation, useGetUserInputLazyQuery, useGetUserQuery, useUpdateConceptMutation, useUnlinkSelectedConceptMutation, useUpdateSelectedConceptMutation, useUpdateUserInputMutation, useGetQuestionnaireLazyQuery } from '~/types/types';
export default defineComponent({
  inheritAttrs: false,
  props: {
    userInput: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    concepts: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    esgActionsActive: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var _props$userInput$id;
    var route = useRoute();
    var questionnaireId = computed(function () {
      return toNumber(route.params.questionnaire);
    });
    var _useGetUserInputLazyQ = useGetUserInputLazyQuery({
        id: (_props$userInput$id = props.userInput.id) !== null && _props$userInput$id !== void 0 ? _props$userInput$id : 0
      }),
      refetchUserInput = _useGetUserInputLazyQ.refetch,
      loadUserInput = _useGetUserInputLazyQ.load;
    var _useUpdateSelectedCon = useUpdateSelectedConceptMutation(),
      updateSelectedConcept = _useUpdateSelectedCon.mutate;
    var _useUpdateConceptMuta = useUpdateConceptMutation(),
      updateConceptMutation = _useUpdateConceptMuta.mutate;
    var _useUpdateUserInputMu = useUpdateUserInputMutation(),
      updateUserInput = _useUpdateUserInputMu.mutate;
    var _useUnlinkSelectedCon = useUnlinkSelectedConceptMutation(),
      unlinkSelectedConcept = _useUnlinkSelectedCon.mutate;
    var _useCreateUserinputCo = useCreateUserinputConceptLinkWithCustomSelectedConceptMutation(),
      createUserInputConceptLinkWithCustomSelectedConcept = _useCreateUserinputCo.mutate;
    var _useGetUserQuery = useGetUserQuery(),
      result = _useGetUserQuery.result;
    var email = computed(function () {
      var _result$value$getUser, _result$value, _result$value$getUser2;
      return (_result$value$getUser = result === null || result === void 0 ? void 0 : (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getUser2 = _result$value.getUser) === null || _result$value$getUser2 === void 0 ? void 0 : _result$value$getUser2.email) !== null && _result$value$getUser !== void 0 ? _result$value$getUser : '';
    });
    var getSchemeInfo = function getSchemeInfo(scheme) {
      if (scheme === RiskManagementScheme.MonitoringEvaluation) return {
        title: 'esg.questionnaire.schemes.monitoringEvaluation.title',
        description: 'esg.questionnaire.schemes.monitoringEvaluation.description'
      };else if (scheme === RiskManagementScheme.Implementation) return {
        title: 'esg.questionnaire.schemes.implementation.title',
        description: 'esg.questionnaire.schemes.implementation.description'
      };else if (scheme === RiskManagementScheme.ReductionSubstitution) return {
        title: 'esg.questionnaire.schemes.reductionSubstitution.title',
        description: 'esg.questionnaire.schemes.reductionSubstitution.description'
      };else if (scheme === RiskManagementScheme.Compensation) return {
        title: 'esg.questionnaire.schemes.compensation.title',
        description: 'esg.questionnaire.schemes.compensation.description'
      };else return {
        title: 'esg.questionnaire.schemes.additionalMeasures.title',
        description: 'esg.questionnaire.schemes.additionalMeasures.description'
      };
    };
    var getSchemeIcon = function getSchemeIcon(scheme) {
      if (scheme === RiskManagementScheme.MonitoringEvaluation) return 'mdi-chart-bar'; // mdi-magnify
      else if (scheme === RiskManagementScheme.Implementation) return 'mdi-hammer';else return 'mdi-plus';
    };
    var getSortingForScheme = function getSortingForScheme(scheme) {
      if (scheme === RiskManagementScheme.MonitoringEvaluation) return 0;else if (scheme === RiskManagementScheme.Implementation) return 1;else if (scheme === RiskManagementScheme.ReductionSubstitution) return 2;else if (scheme === RiskManagementScheme.Compensation) return 3;else return 4;
    };
    var customSelectedConcepts = computed(function () {
      var custom = [];
      if (!props.userInput) return custom;
      props.userInput.selectedConcepts.forEach(function (e) {
        if (e.concept.scheme === null && !e.concept.isSubmeasure) {
          custom.push(e);
        }
      });
      return custom;
    });
    var selectedConceptsMapping = computed(function () {
      var mapping = {};
      props.concepts.forEach(function (e) {
        mapping[e.id] = {
          implementationStatus: ImplementationStatus.NotPlanned
        };
      });
      props.userInput.selectedConcepts.forEach(function (e) {
        mapping[e.concept.id] = _objectSpread(_objectSpread({}, mapping[e.concept.id]), e);
      });
      return mapping;
    });
    var getImplementationStatus = function getImplementationStatus(id) {
      var _selectedConceptsMapp, _selectedConceptsMapp2;
      return (_selectedConceptsMapp = selectedConceptsMapping === null || selectedConceptsMapping === void 0 ? void 0 : (_selectedConceptsMapp2 = selectedConceptsMapping.value[id]) === null || _selectedConceptsMapp2 === void 0 ? void 0 : _selectedConceptsMapp2.implementationStatus) !== null && _selectedConceptsMapp !== void 0 ? _selectedConceptsMapp : 'NOT_PLANNED';
    };
    var getComment = function getComment(id) {
      var _selectedConceptsMapp3, _selectedConceptsMapp4;
      return (_selectedConceptsMapp3 = selectedConceptsMapping === null || selectedConceptsMapping === void 0 ? void 0 : (_selectedConceptsMapp4 = selectedConceptsMapping.value[id]) === null || _selectedConceptsMapp4 === void 0 ? void 0 : _selectedConceptsMapp4.comment) !== null && _selectedConceptsMapp3 !== void 0 ? _selectedConceptsMapp3 : '';
    };
    var optionsByScheme = computed(function () {
      var conceptsMapped = props.concepts.map(function (e) {
        return {
          conceptId: e.id,
          concept: e,
          implementationStatus: ImplementationStatus.NotPlanned
        };
      });
      var grouped = groupBy(conceptsMapped, function (e) {
        return e.concept.scheme;
      });
      var optionsByScheme = Object.entries(grouped).sort(function (a, b) {
        return getSortingForScheme(a[0]) - getSortingForScheme(b[0]);
      });
      return optionsByScheme;
    });
    var handleIsNotRelevantChange = function handleIsNotRelevantChange(value) {
      updateUserInput({
        input: {
          id: props.userInput.id,
          isNotRelevant: value
        }
      });
    };
    var _useGetQuestionnaireL = useGetQuestionnaireLazyQuery({
        id: questionnaireId.value
      }, {
        fetchPolicy: 'network-only' // don't use cache because we want to update the completion status after updating selected concepts
      }),
      loadQuestionnaire = _useGetQuestionnaireL.load,
      refetchQuestionnaire = _useGetQuestionnaireL.refetch;
    var handleConceptInput = function handleConceptInput(value) {
      createUserInputConceptLinkWithCustomSelectedConcept({
        userInputId: props.userInput.id,
        implementationStatus: props.esgActionsActive === true ? ImplementationStatus.NotPlanned : ImplementationStatus.Doing,
        concept: {
          id: value.id,
          label: value.label,
          status: value.state || 'PROPOSED',
          kind: ConceptKind.Company,
          createdBy: email.value,
          isSubmeasure: value.isSubmeasure || false,
          sourceUrl: '',
          citation: ''
        }
      }).then(function () {
        loadUserInput() || refetchUserInput();
      }).catch(function (error) {
        console.error('handleConceptInput Mutation error:', error);
      });
    };
    var handleUpdateConcept = function handleUpdateConcept(id) {
      var option = selectedConceptsMapping === null || selectedConceptsMapping === void 0 ? void 0 : selectedConceptsMapping.value[id];
      var status;
      if (option.implementationStatus === ImplementationStatus.NotPlanned) status = ImplementationStatus.Doing;else if (option.implementationStatus === ImplementationStatus.Doing) status = ImplementationStatus.In_12Months;else if (option.implementationStatus === ImplementationStatus.In_12Months) status = ImplementationStatus.NotPlanned;else status = ImplementationStatus.NotPlanned;
      updateSelectedConcept({
        input: {
          userInputId: props.userInput.id,
          conceptId: id,
          implementationStatus: status,
          comment: ''
        }
      }).then(function (v) {
        loadQuestionnaire() || refetchQuestionnaire();
      });
    };
    var handleUpdateConceptLabel = function handleUpdateConceptLabel(id, label) {
      updateConceptMutation({
        input: {
          id: id,
          kind: ConceptKind.Company,
          status: ConceptStatus.Proposed,
          label: label
        }
      });
    };
    var handleUpdateConceptComment = function handleUpdateConceptComment(comment, concept) {
      var option = selectedConceptsMapping[concept.id];
      updateSelectedConcept({
        input: {
          userInputId: props.userInput.id,
          conceptId: concept.id,
          comment: comment,
          implementationStatus: (option === null || option === void 0 ? void 0 : option.implementationStatus) || ImplementationStatus.NotPlanned
        }
      });
    };
    var removeSelectedConcept = function removeSelectedConcept(conceptId) {
      var _props$userInput$id2;
      unlinkSelectedConcept({
        userInputId: (_props$userInput$id2 = props.userInput.id) !== null && _props$userInput$id2 !== void 0 ? _props$userInput$id2 : 0,
        conceptId: conceptId
      }).then(function () {
        loadUserInput() || refetchUserInput();
      }).catch(function (error) {
        console.error('removeSelectedConcept Mutation error:', error);
      });
    };
    var actionsEditable = computed(function () {
      return props.esgActionsActive && props.isEditable;
    });
    return {
      questionnaireId: questionnaireId,
      getSchemeInfo: getSchemeInfo,
      getSchemeIcon: getSchemeIcon,
      getImplementationStatus: getImplementationStatus,
      getComment: getComment,
      updateSelectedConcept: updateSelectedConcept,
      unlinkSelectedConcept: unlinkSelectedConcept,
      updateUserInput: updateUserInput,
      customSelectedConcepts: customSelectedConcepts,
      selectedConceptsMapping: selectedConceptsMapping,
      optionsByScheme: optionsByScheme,
      actionsEditable: actionsEditable,
      handleIsNotRelevantChange: handleIsNotRelevantChange,
      handleConceptInput: handleConceptInput,
      handleUpdateConcept: handleUpdateConcept,
      handleUpdateConceptComment: handleUpdateConceptComment,
      removeSelectedConcept: removeSelectedConcept,
      handleUpdateConceptLabel: handleUpdateConceptLabel
    };
  }
});