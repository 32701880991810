import { render, staticRenderFns } from "./QuestionCompanyConcepts.vue?vue&type=template&id=f10bd9ec"
import script from "./QuestionCompanyConcepts.vue?vue&type=script&lang=ts"
export * from "./QuestionCompanyConcepts.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./QuestionCompanyConcepts.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/Question.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,EsgChipWithImplementationStatus: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/ChipWithImplementationStatus.vue').default,IxFormInputConceptCombobox: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/ConceptCombobox.vue').default,EsgChipWithSubmeasures: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/ChipWithSubmeasures.vue').default,EsgThreeStepCreateMeasure: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/ThreeStepCreateMeasure.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCheckbox,VCol,VIcon,VRow})
